import * as React from 'react';
import Shell from '../layout/shell';
import './signup.scss';
import Footer from '../components/footer';
import {
  Form,
  TextInput,
  Select,
  CheckboxInput,
  TextArea,
  Button,
  EmailInput,
  LabelWrapper,
} from '@rocketmakers/armstrong-edge';
import addToMailchimp from 'gatsby-plugin-mailchimp';

interface IMailChimpResult {
  msg: string;
  result: 'error' | 'success';
}
const SignUp: React.FC = () => {
  const [pending, setPending] = React.useState(false);
  const [result, setResult] = React.useState<IMailChimpResult>();
  const { formProp, formState } = Form.use({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    category: '',
    twitterURL: '',
    websiteURL: '',
    postcode: '',
    message: '',
    featured: false,
  });

  const categoryOptions = [
    'Enviroment',
    'Energy',
    'Health & Wellness',
    'People & Social impact',
    'Behaviour & Culturre',
    'Transport',
    'Other',
    'Food',
    'Biotech',
    'Finance',
    'Real estate',
    'Deeptech',
    'Software agency',
    'Consultancy',
    'Architect',
    'Recruitment agency',
    'Meetup',
    'Support organisation',
    'Incubator',
    'Investor',
    'Community',
    'Games agency',
  ];
  const createSelectOptions = (options: string[]) => {
    return options.map((option) => ({ id: option, name: option }));
  };
  const submit = async (e) => {
    setPending(true);
    e.preventDefault();

    const result: IMailChimpResult = await addToMailchimp(formState?.email, {
      FNAME: formState?.firstName,
      LNAME: formState?.lastName,
      EMAIL: formState?.email,
      COMPANY: formState?.company,
      CATEGORY: formState?.category,
      TWITTERURL: formState?.twitterURL,
      WEBSITEURL: formState?.websiteURL,
      POSTCODE: formState?.postcode,
      MESSAGE: formState?.message,
      FEATURED: formState?.featured,
    });

    setResult(result);
    setPending(false);
  };

  return (
    <Shell>
      <div className="header-stripe">Join the map</div>
      {result?.result === 'success' ? (
        <div className="submitted-success fixed-width">
          <div className="success-inner">
            <h3>Thank you for requesting to join the Tech for Good Bristol and Bath map</h3>
            <p>We update the map manually on a monthly basis</p>
            <a className="btn" href={'/'}>
              Back to the map
            </a>
          </div>
        </div>
      ) : (
        <div className="signup-page fixed-width">
          <div id="mc_embed_signup">
            <div id="mc_embed_signup_scroll">
              <div className="headings">
                <h2>Get in touch</h2>
                <p>Complete this form to contact us or apply to be featured on the Tech For Good Map</p>
              </div>
              <div className="indicates-required"></div>

              <LabelWrapper labelContent="First Name">
                <TextInput bind={formProp('firstName').bind()} />
              </LabelWrapper>

              <LabelWrapper labelContent="Last Name">
                <TextInput bind={formProp('lastName').bind()} />
              </LabelWrapper>
              <LabelWrapper labelContent="Email Address">
                <EmailInput className="required email" bind={formProp('email').bind()} />
              </LabelWrapper>

              <CheckboxInput bind={formProp('featured').bind()} label="I want to be featured on the map" />

              {formState?.featured ? (
                <>
                  <LabelWrapper labelContent="Company Name">
                    <TextInput bind={formProp('company').bind()} />
                  </LabelWrapper>
                  <LabelWrapper labelContent="Category">
                    <Select
                      bind={formProp('category').bind()}
                      options={createSelectOptions(categoryOptions)}
                      placeholderOption="Select a Category"
                    />
                  </LabelWrapper>
                  <LabelWrapper labelContent="Website URL">
                    <TextInput className=" url" bind={formProp('websiteURL').bind()} />
                  </LabelWrapper>
                  <LabelWrapper labelContent="Twitter URL">
                    <TextInput bind={formProp('twitterURL').bind()} />
                  </LabelWrapper>
                  <LabelWrapper labelContent="Postcode">
                    <TextInput bind={formProp('postcode').bind()} />
                  </LabelWrapper>
                </>
              ) : (
                <LabelWrapper labelContent="Message">
                  <TextArea className="long-text" bind={formProp('message').bind()} />
                </LabelWrapper>
              )}
              <div className="btn-container">
                <Button pending={pending} className="btn green" onClick={submit}>
                  Submit
                </Button>
                {result?.result === 'error' && (
                  <p className="error">
                    There was a problem submitting your details. Please try again or email{' '}
                    <a href="mailto:briony@rocketmakers.com">briony@rocketmakers.com</a>
                  </p>
                )}
                <p>We update the map manually on a monthly basis.</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </Shell>
  );
};

export default SignUp;
